import stdlib from "@stdlib/dist-flat"
// import beta from "@stdlib/math/base/special/beta"
import { VegaLite } from "react-vega"
import React from "react"

let BetaPDF = () => {
  console.log(stdlib.base.dists.beta.pdf(0.25, 5.0, 1.0))

  let data = {}
  let spec = {}
  return (
    <>
      {/* {stdlib.base.beta.pdf(0.25, 5.0, 1.0)} */}
      <VegaLite spec={spec} data={data} />
    </>
  )
}

export default BetaPDF
