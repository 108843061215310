import retention from "../../../../content/publications/KeyMap/retention.svg";
import slack from "../../../../content/publications/KeyMap/Slack_RGB.svg";
import overleaf from "../../../../content/publications/KeyMap/overleaf_wide_colour_light_bg.svg";
import stove from "../../../../content/publications/KeyMap/stove.svg";
import Key from "../../../../src/components/Key";
import KeyMap from "../../../../src/components/LoadableKeyMap";
import keyMapDemoCommands from "../../../../src/components/KeyMapDemoCommands";
import * as React from 'react';
export default {
  retention,
  slack,
  overleaf,
  stove,
  Key,
  KeyMap,
  keyMapDemoCommands,
  React
};