import BetaPDF from "../../../../content/blog/bandit/BetaPDF";
import GraphBandit from "../../../../content/blog/bandit/GraphBandit";
import Bandit from "../../../../content/blog/bandit/Bandit";
import AnimatedBandit from "../../../../content/blog/bandit/AnimatedBandit";
import { randomBernoulli } from "d3";
import { epsilonGreedy, ucb, thompsonSampling } from "../../../../content/blog/bandit/algorithms";
import * as React from 'react';
export default {
  BetaPDF,
  GraphBandit,
  Bandit,
  AnimatedBandit,
  randomBernoulli,
  epsilonGreedy,
  ucb,
  thompsonSampling,
  React
};